<template>
  <div>
    <div class="modal_header">
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              <h5>{{ $t("message.reports") }}</h5>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <export-excel
                class="btn excel_btn"
                :data="model"
                :fields="excel_fields"
                @fetch="controlExcelData()"
                worksheet="Заявки"
                :name="`Отчет ${name}.xls`"
              >
                <el-button size="mini" icon="el-icon-document-delete">
                  Excel
                </el-button>
              </export-excel>
              <el-button @click="resetForm()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="modal-doctoe-info">
      <el-row :gutter="10" class="credit_row">
        <el-col :span="6">
          <div class="font__W">{{ $t("message.ifo") }}</div>
          <div>{{ name }}</div>
        </el-col>
        <el-col :span="6">
          <div class="font__W">{{ $t("message.quantity") }}</div>
          <div>{{ reports ? reports.total_count : 0 }}</div>
        </el-col>

        <el-col :span="4">
          <div class="font__W">{{ $t("message.totals") }}</div>
          <div>{{ reports ? reports.total_bonus : 0 | formatMoney }}</div>
        </el-col>
        <el-col :span="4">
          <div class="font__W">{{ $t("message.paid") }}</div>
          <div>{{ reports ? reports.paid_bonus : 0 | formatMoney }}</div>
        </el-col>

        <el-col :span="4">
          <div class="font__W">{{ $t("message.remainder") }}</div>
          <div>{{ reports ? reports.remainder : 0 | formatMoney }}</div>
        </el-col>
      </el-row>

      <div class="text-center float-left sorddata my-3">
        <el-date-picker
          size="mini"
          v-model="filterForm.orders_from"
          type="date"
          :format="'dd.MM.yyyy'"
          :value-format="'dd.MM.yyyy'"
          :placeholder="$t('message.start_date')"
        ></el-date-picker>
        <el-date-picker
          size="mini"
          v-model="filterForm.orders_to"
          type="date"
          :format="'dd.MM.yyyy'"
          :value-format="'dd.MM.yyyy'"
          :placeholder="$t('message.end_date')"
        ></el-date-picker>
      </div>
      <div class="float-right m-3 d-flex">
        <div class="mr-3 credit_row p-2" style="color: green">
          {{ selected_bonus_amount | formatNumber }}
        </div>
        <el-button
          type="success"
          @click="openDialog()"
          :disabled="!checkedOrders.length"
        >
          {{ $t("message.pay") }}
        </el-button>
      </div>

      <table
        class="table table-hover table-bordered mt-3 table__cerditne__forma"
        v-loading="loadingData"
      >
        <crm-pagination
          :pagination="pagination"
          @c-change="updatePagination"
        ></crm-pagination>

        <el-dialog
          :title="$t('message.pay')"
          :visible.sync="dialogVisible"
          width="30%"
          :append-to-body="true"
        >
          <el-select
            v-model="payment_type_id"
            filterable
            :placeholder="$t('message.payment_type')"
          >
            <el-option
              v-for="item in paymentTypes"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-button
            type="success"
            style="margin-left: 80px; padding: 12px !important"
            @click="paidBonus"
            :disabled="!payment_type_id"
          >
            {{ $t("message.pay") }}
          </el-button>
        </el-dialog>

        <thead>
          <tr>
            <th>#</th>
            <th>id</th>
            <th scope="col">{{ $t("message.ifo") }}</th>
            <th scope="col">{{ $t("message.date_0") }}</th>
            <th scope="col">{{ $t("message.conclusion_date") }}</th>
            <th scope="col">{{ $t("message.service") }}</th>
            <th scope="col">{{ $t("message.amount") }}</th>
            <th scope="col">{{ $t("message.bonus") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in form"
            :key="index"
            :style="colorPicker(item)"
          >
            <td>
              <el-checkbox
                :disabled="item.bonus_amount == item.paid_amount"
                @change="addPayment(item, $event)"
              ></el-checkbox>
            </td>
            <td>{{ item.order_id }}</td>
            <td>
              {{
                item.order && item.order.patient
                  ? item.order.patient.surname +
                    " " +
                    item.order.patient.first_name
                  : ""
              }}
            </td>
            <td>{{ item.order ? item.order.created_at : "" }}</td>

            <td
              class="service"
              v-if="item.order && item.order.conculationsData.length"
            >
              {{ item.order.conculationsData[0].created_at }}
            </td>

            <td v-else>
              {{ " - " }}
            </td>
            <td class="service" v-if="item.order && item.order.services">
              <span
                v-for="(service, index) in item.order.services"
                :key="index"
              >
                {{ service.name ,}}
              </span>
            </td>

            <td v-else>
              {{ " - " }}
            </td>
            <td>
              <span>{{ item.order.total_price | formatMoney }}</span>
            </td>
            <td>
              <span class="act_color">{{
                item.bonus_amount | formatMoney
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from "vuex";
import form from "@/utils/mixins/form";
export default {
  mixins: [form],
  props: ["selected"],
  data() {
    return {
      payment_type_id: null,
      dialogVisible: false,
      checkedOrders: [],
      filterForm: {
        orders_from: "",
        orders_to: "",
      },
      name: "",
      loadingData: false,
      excel_fields: {},
    };
  },
  computed: {
    ...mapGetters({
      reports: "partnerDoctorReport/reports",
      model: "partnerDoctorReport/model",
      paymentTypes: "paymentTypes/list",
      pagination: "partnerDoctorReport/show_pagination",
    }),
    paid_bonus: function () {
      let orders = this.form;
      let prices = 0;
      for (let i = 0; i < orders.length; i++) {
        const element = orders[i];

        prices += parseFloat(element.paid_amount);
      }
      return prices;
    },
    total_order_amount: function () {
      let data = this.form;
      let prices = 0;
      for (let i = 0; i < data.length; i++) {
        const element = data[i].order;

        prices += parseFloat(element.total_price);
      }
      return prices;
    },
    total_bonus: function () {
      let orders = this.form;
      let prices = 0;
      for (let i = 0; i < orders.length; i++) {
        const element = orders[i];

        prices += parseFloat(element.bonus_amount);
      }
      return prices;
    },
    selected_bonus_amount: function () {
      let orders = this.checkedOrders;
      let prices = 0;
      if (orders) {
        for (let i = 0; i < orders.length; i++) {
          const element = orders[i];

          prices += parseFloat(element.bonus_amount);
        }
      }
      return prices;
    },
  },

  watch: {
    "pagination.page": {
      handler: async function (newVal, oldVal) {
        await this.showReport();
      },
    },
    "pagination.per_page": {
      handler: async function (newVal, oldVal) {
        await this.showReport();
      },
    },
    columns: {
      handler: function () {
        this.controlExcelData();
      },
      deep: true,
    },
    filterForm: {
      handler: function (newVal, oldVal) {
        this.showReport();
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    // updateOrderListPagination(pagination) {
    //   this.model.data.pagination[pagination.key] = pagination.value;
    //   this.loadingData = true;
    //   this.showModel({
    //     id: this.selected.bonusable_id,
    //     orders_from: this.filterForm.orders_from,
    //     orders_to: this.filterForm.orders_to,
    //     [pagination.key]: pagination.value,
    //   })
    //     .then((res) => {
    //       this.loadingData = false;
    //     })
    //     .catch((err) => {
    //       this.loadingData = false;
    //       this.$alert(err);
    //     });
    // },
    ...mapActions({
      updatePagination: "partnerDoctorReport/updateShowPagination",
      paymentTypesList: "paymentTypes/index",
      massPaid: "paymentBonus/massPaid",
      showModel: "partnerDoctorReport/show",
    }),
    colorPicker(item) {
      if (item.bonus_amount <= item.paid_amount) {
        return "background-color: rgb(171 255 168 / 62%)";
      } else {
        return "background-color: #ffffff";
      }
    },
    openDialog() {
      this.payment_type_id = null;
      this.dialogVisible = true;
    },
    paidBonus() {
      const query = {
        items: this.checkedOrders,
        bonusable_type: this.selected.bonusable_type,
        bonusable_id: this.selected.bonusable_id,
        payment_type_id: this.payment_type_id,
      };
      this.massPaid(query).then((res) => {
        this.payment_type_id = null;
        this.dialogVisible = false;
        this.checkedOrders = [];
        this.showReport();
      });
    },
    addPayment(item, event) {
      if (event == true) {
        this.checkedOrders.push(item);
      }
      if (event == false) {
        const index = this.checkedOrders.indexOf(item);
        if (index > -1) {
          this.checkedOrders.splice(index, 1);
        }
      }
    },
    showReport() {
      this.loadingData = true;
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        id: this.selected.bonusable_id,
        orders_from: this.filterForm.orders_from,
        orders_to: this.filterForm.orders_to,
      };
      this.showModel(query)
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
    },

    afterOpen() {
      this.loadingData = true;
      const query = {
        ...this.filter,
        ...this.pagination,
        ...this.sort,
        id: this.selected.bonusable_id,
        orders_from: this.filterForm.orders_from,
        orders_to: this.filterForm.orders_to,
      };
      this.name = this.selected.bonusable ? this.selected.bonusable.name : "";
      this.showModel(query)
        .then((res) => {
          this.loadingData = false;
        })
        .catch((err) => {
          this.loadingData = false;
        });
      this.paymentTypesList();
      this.controlExcelData();
    },
    controlExcelData() {
      this.excel_fields = {
        id: "order_id",
        "И.Ф.О": {
          field: "order",
          callback: (value) => {
            return value.patient.surname + " " + value.patient.first_name;
          },
        },
        "Дата заключения": {
          field: "order",
          callback: (value) => {
            return value.conculationsData.length
              ? value.conculationsData[0].created_at
              : "-";
          },
        },
        Услуга: {
          field: "order",
          callback: (value) => {
            let array = [];
            if (value.services.length) {
              value.services.forEach((value, index) => {
                if (!array.includes(value.name)) {
                  array.push(value.name);
                }
              });
            }
            return array.toString();
          },
        },
        "Дата заказа": {
          field: "order",
          callback: (value) => {
            return value.created_at;
          },
        },
        Сумма: {
          field: "order",
          callback: (value) => {
            return value.total_price ? value.total_price : 0;
          },
        },
        Бонус: "bonus_amount",
      };
    },
    resetForm() {
      this.$emit("c-close", { drawer: "drawer" });
    },
  },
};
</script>
<style lang="scss">
.modal-doctoe-info {
  margin: 20px 30px;
}
.credit_row {
  margin-left: 10px;
  margin-right: -10px;
  text-align: center;
  background-color: #e4e7ed;
  padding: 10px 0;
  .font__W {
    font-weight: 600;
    font-size: 15px;
  }
  .color_or {
    background: #ffbb58;
    color: #fff;
    padding: 2px 7px;
    border-radius: 10px;
  }
  div {
    font-size: 12px;
  }
}
.act_color {
  background-color: #67c23a;
  color: #fff;
  padding: 2px 7px;
  border-radius: 10px;
}
.not-paid {
  border: 1px solid #dee2e6;
  padding: 1px 4px;
  border-radius: 10px;
  background-color: #fff;
}
.watch--story {
  padding: 7px 8px;
  background-color: #409eff;
  color: #fff;
}
.table__cerditne__forma {
  th {
    font-weight: 600 !important;
    color: #000 !important;
    font-size: 14px !important;
  }
  th,
  td {
    padding: 0.55rem !important;
  }
  .action_tr {
    background: #ffbb58;
  }
}
</style>